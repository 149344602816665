var educations = [
  {
    id: 0,
    school: "Bexley High School",
    logo: "https://willfeldman.com/src/bexley_high_school_logo.jpg",
    subtitle: "High School Diploma with Honors",
    tags: ["Summa Cum Laude"],
    years: "Aug. 2016 - May 2020",
  },
  {
    id: 1,
    school: "Northeastern University",
    logo: "https://willfeldman.com/src/northeastern_logo.jpg",
    subtitle: "BS in Computer Science and Business Administration",
    tags: ["Summa Cum Laude", "Honors Program"],
    years: "Sept. 2020 - expected May 2025",
  },
];

module.exports = { educations };
